import styles from './KoszykLisieckiFooter.module.scss';
import { getWebshopFooter } from '../../../containers/cms-container/services';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
const przelewy24Icon = require("../../../assets/payments/przelewy24.svg").default;

const getFooter = (languageCode: string): any => getWebshopFooter(languageCode);

export default function KoszykLiscieckiFooter() {

    const [footer, setFooter] = useState<string>();
    const getLanguageCode = (): string | null => localStorage.getItem("lang");

    useEffect(() => {
        const languageCode = getLanguageCode();
        if (languageCode) {
            getFooter(languageCode).then((response: any) => {
                setFooter(response.data);
            })
        }
    }, [])

    const renderSections = () => {
        if (!footer) return null;

        const sections = footer.split('<h3>');

        return sections.map((section, index) => {
            if (index === 0) return null;
            const [title, content] = section.split('</h3>');
            return (
                <div key={index} className={styles.footer_section}>
                    <h3>{title}</h3>
                    {parse(content)}
                </div>
            );
        });
    };

    return (
        <>
            <div className={styles.footer_container}>
                <div className={styles.columns}>{renderSections()}</div>
                <div className={styles.second_row}>
                    <div className={styles.copyright}>
                        <div className={styles.left}>
                            <p>Powered by IT PLM 2024 © IsoTech Sp. z o.o.. All Rights Reserved</p>
                        </div>
                        <div className={styles.right}>
                            <img src={przelewy24Icon} alt="Przelewy24" />
                        </div>
                    </div>
                </div>
            </div >
        </>
    )

}